import React from 'react';
// import { MenuContext, animationDuration } from './src/components/Layout/Header';
import Layout from 'src/components/Layout';
// import AppProvider from './src/gatsby-universal/store/provider';
// import wrapPageElementWithTransition from './src/gatsby-universal/helpers/wrapPageElement';

// React Context in Browser
// eslint-disable-next-line react/prop-types
// export const wrapRootElement = ({ element }) => {
//   return <AppProvider>{element}</AppProvider>;
// };

// Page Transitions
// export const wrapPageElement = wrapPageElementWithTransition;

// const Provider = ({ children }) => {
//   const menuState = useMenuState();
//   return <MenuContext.Provider value={menuState}>{children}</MenuContext.Provider>;
// };

// class Provider2 extends React.Component {
//   state = {
//     navOpen: false,
//     animation: false,
//     toggleMenu: () => { 
//       this.setState({ 
//         navOpen: !this.state.navOpen,
//         // animation: true
//       });
//     }
//   };

//   t = 0;

//   componentDidUpdate() {
//     if (this.state.animation) {
//       if (this.t)
//         clearTimeout(this.t);
//       this.t = window.setTimeout(() => {
//         this.setState({ animation: false });
//       }, animationDuration + 50);
//     }
//   }
//   componentWillUnmount() {
//     if (this.t) clearTimeout(this.t);
//   }

//   render() {
//     return <MenuContext.Provider value={this.state}>{this.props.children}</MenuContext.Provider>;
//   }
// }


// const Wrapper = ({ element }) => {
//   return <Provider2>{element}</Provider2>;
// };

// export const wrapPageElement = Wrapper;


export const wrapPageElement = ({ element, props }) => {

  const { metaTitle, title, ...rest } =  props?.data?.markdownRemark?.frontmatter || {}

  return <Layout {...rest} pageTitle={metaTitle || title}>{element}</Layout>;
};
