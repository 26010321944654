import React from 'react';
import styled from 'styled-components';
import { Row, Col, ColFlex } from 'src/components/Grid';
import siteConfig from '../../../site-config.json';
import { mediaDesktopFirst, mediaMobileFirst } from 'src/helpers/responsive';
import { vBlockSize, colorLightText } from '../GlobalStyle';

const Container = styled.footer`
  margin-top: ${2 * vBlockSize}px;
  /* background: linear-gradient(180deg, #dce1e5 0%, #fff 40px); */
  color: ${colorLightText};
  .copy {
    font-style: normal;
  }
  a {
    color: ${colorLightText};
    text-decoration: none;
  }
  ${Col} {
    padding-top: 2px;
    padding-bottom: 2px;
  }  
  ${mediaMobileFirst(`
  `, `
    ${Col} {
      padding-top: 0;
      padding-bottom: 0;
    }
    p:last-child {
      margin-bottom: ${0.5 * vBlockSize}px;
    }
  `)}
  ${Row} {
    margin: ${1.5 * vBlockSize}px auto ${vBlockSize}px;
  }
  .ta-r {
    ${mediaDesktopFirst(`
      text-align: right;
    `,  ``, `
      text-align: left;
    `)}
  }
`;

const Footer = ({ 
  address = siteConfig.address,
  siteTitle = siteConfig.siteTitle,
  phoneNumber = siteConfig.phoneNumber,
  mapLink = siteConfig.mapLink,
  mail = siteConfig.mail
  // siteDescription = siteConfig.siteDescription // @todo
}) => (
  <Container>
    <Row as="address">
      <Col s1={2}>
        <p className="copy">© 2020 {siteTitle}</p>
      </Col>
      <Col s1={2}>
        <p className="ta-r"><a href={mapLink}>{address}</a></p>
        </Col>
      <Col s1={2} order={[1, 0]}>
        <p><a href={`mailto:${mail}`}>{mail}</a></p>
      </Col>
      <Col s1={2}>
        <p className="ta-r"><a href={`tel:${phoneNumber}`}>{phoneNumber}</a></p>
        </Col>
    </Row>
  </Container>
);

export default Footer;