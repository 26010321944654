import React from 'react';
import styled, { css } from 'styled-components';

export const MenuIconButton = styled.button`
  background: none;
  border: none;
  outline: none;
  font-size: 16px;
  line-height: 1em;
  display: inline-block;
  overflow: hidden;
  z-index: 999;
`;

export const MenuIconContainer = styled.span`
  /* font-size: 16px; */
  font-size: 1em;
  line-height: 1em;
  display: inline-block;
  overflow: hidden;
  padding: 0.125em 0.25em;
  width: 1.5em;
  height: 1.5em;
  z-index: 999;
  span {
    display: block;
    width: 1em;
    height: 0.125em;
    margin: 0.25em 0;
    background-color: #000;
    opacity: 1;
    transform: translate3d(0) rotate3d(0);
    transition: all 200ms;
  }
  &.o-x {
    span:nth-child(1) {
      transform: translate3d(0, 0.375em, 0) rotate3d(0, 0, 1, 45deg);
    }
    span:nth-child(2) {
      opacity: 0;
    }
    span:nth-child(3) {
      transform: translate3d(0, -0.375em, 0) rotate3d(0, 0, 1, -45deg);
    }
  }
`;

const MenuIcon = ({ className = '', isOpen = false, onClick = null }) => (
  <MenuIconContainer className={className + (isOpen ? ' o-x' : '')} isOpen={isOpen} onClick={onClick}>
    <span />
    <span />
    <span />
  </MenuIconContainer>
);

const MenuButton = ({ className = '', isOpen = false, onClick }) => (
  <MenuIconButton className={className} onClick={onClick} aria-label="Menu">
    <MenuIcon isOpen={isOpen} />
  </MenuIconButton>
);

export default MenuButton;