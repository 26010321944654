import React, { useState, useRef, useEffect, memo } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { vBlockSize } from '../GlobalStyle';
import { Row, ColFlex } from 'src/components/Grid';
import { mediaDesktopFirst, mediaMobileFirstWithBlock, blocks, mediaMobileFirst } from 'src/helpers/responsive';
import { MenuIconButton } from './MenuIcon';
import MenuIcon from './MenuIcon';
import siteConfig from '../../../site-config.json';

export const animationDuration = 200;
// const animationEasing = 'cubic-bezier(0.16, 1, 0.3, 1)'; // expo out
const animationEasing = 'ease'; // expo out


const BrandContainer = styled(ColFlex)``;

const Brand = ({ className = '', title }) => (
  <h1 className={className || null}>
    <Link to="/">{title}</Link>
  </h1>
);

const HeaderContainer = styled.header<{ menuOpen?: boolean; }>`
  font-size: 16px;
  background-color: #fff;
  top: 0;
  ${mediaDesktopFirst(``, ``, `
    width: 100%;
    position: fixed;
    z-index: 9;  
  `)}
  h1 {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5em;
    a {
      ${mediaDesktopFirst(``, ``, `
        position: relative;
        display: inline-block;
        line-height: 1.5em;
        margin: -${0.5 * vBlockSize}px -${100 / blocks[0]}vw;
        padding: ${0.5 * vBlockSize}px ${100 / blocks[0]}vw;
      `)}
    }

  }
  a {
    text-decoration: none;
    padding: ${0.5 * vBlockSize}px 0;
  }
  ${Row} {
    justify-content: space-between;
  }
  ${MenuIconButton} {
    display: none;
    ${mediaDesktopFirst(``, ``, `
      display: block;
      position: absolute;
      top: 0;
      right: 0;
    `)};
    ${mediaMobileFirstWithBlock((block, unit) => /* css */`
      padding: ${vBlockSize}px ${block * 2}${unit};
    `, () => /* css */`
      padding: 0;
    `)}  
  }
`;

const Menu = styled.ul`
  margin: 0;
  ${mediaDesktopFirst(``, ``, /* css */`
    font-size: 1rem;
  `)}
  li {
    display: flex;
    list-style: none;
    padding: 0;
    display: inline-block;
    ${mediaDesktopFirst(`
      margin-left: ${vBlockSize}px;
      &:first-child {
        margin-left: 0;
      }
    `, ``, `
      display: block;
      margin-left: 0;
      a {
        margin: 0 -${100 / blocks[0]}vw;
        padding: ${0.5 * vBlockSize}px ${100 / blocks[0]}vw;
        display: block;
      }
    `)}
    ${mediaMobileFirst(``, `
      a {
        &:hover {
          text-decoration: underline;
        }
      }
    `)}
  }
`;

const NavigationInner = styled.nav`
  h1 {
    display: none;
    visibility: hidden;
    padding-bottom: ${vBlockSize}px;
    border-bottom: 1px solid #dce1e5;
    margin-bottom: ${0.5 * vBlockSize}px;
    transition: visibility ${animationDuration}ms;
    transition-timing-function: ${animationEasing};
  }
  width: 100%;
  /* display: block; */
  /* display: none; */
  ${mediaDesktopFirst(``, ``, /* css */`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 998;
    background: #fff;
    visibility: hidden;
    opacity: 0;
    &.animation {
      transition: opacity ${animationDuration}ms, visibility ${animationDuration}ms;\
      transition-timing-function: ${animationEasing};
      ${Menu} {
        transition transform ${animationDuration}ms;
        transition-timing-function: ${animationEasing};
      }
    }
    ${Menu} {
      transform: translate3d(120%, 0, 0);
    }
    h1 {
      display: block;
    }
  `)}
  .is-open & {
    ${mediaDesktopFirst(``, ``, `
      visibility: visible;
      opacity: 1;
      ${Menu} {
        transform: translate3d(0, 0, 0);
      }
      h1 {
        visibility: visible;
      }
    `)}
  }

  ${mediaMobileFirstWithBlock((block, unit) => /* css */`
    padding: ${vBlockSize}px ${block * 2}${unit};
  `, (block, unit) => /* css */`
    padding: 0;
  `)}
`;

const Navigation = styled(ColFlex)``;

export function useMenuState() { // : [boolean, boolean, () => void] {

  const [navOpen, setNavOpen] = useState(false);
  const [animation, setAnimation] = useState(false);
  const tRef = useRef(0);

  useEffect(() => {
    if (animation) {
      if (tRef.current)
        clearTimeout(tRef.current);
      tRef.current = window.setTimeout(() => {
        setAnimation(false);
      }, animationDuration + 50);
    }
    return () => {
      if (tRef.current)
        clearTimeout(tRef.current);
    };
  }, [animation]);

  function _toggleMenu(doAnimation = true) {
    setNavOpen(!navOpen);
    setAnimation(doAnimation);
  }

  function toggleMenu(delay = 0) {
    if (delay > 0) setTimeout(() => _toggleMenu(), delay);
    else _toggleMenu();
  }


  return { navOpen, animation, toggleMenu };
}

// export const MenuContext = React.createContext({
//   navOpen: false,
//   animation: false,
//   toggleMenu: () => {}
// });


const Header = memo<{ siteTitle?: string }>(({ siteTitle = siteConfig.siteTitle }) => {

  const { navOpen, animation, toggleMenu } = useMenuState();
  // const { navOpen, animation, toggleMenu } = useContext(MenuContext);

  function handleMenuClick(e) {
    if (navOpen && e.target.tagName === 'A') {
      toggleMenu(30);
    }
  }

  return (
    <HeaderContainer className={navOpen ? 'is-open' : null}>
      <Row>
        <BrandContainer>
          <Brand title={siteTitle} />
        </BrandContainer>
        <Navigation flex="0 0 content">
          <NavigationInner className={animation ? 'animation' : null} onClickCapture={handleMenuClick} role="navigation" aria-label="Main">
            <Brand title={siteTitle} />
            <Menu>
              <li>
                <Link to="/private-services">Private Services</Link>
              </li>
              <li>
                <Link to="/nhs-repeat-prescriptions">NHS Repeat Prescriptions</Link>
              </li>
              <li>
                <Link to="/deliveries">Deliveries</Link>
              </li>
              <li>
                <Link to="/faq">Faq</Link>
              </li>
            </Menu>
          </NavigationInner>
          <MenuIcon onClick={toggleMenu} isOpen={navOpen} />
        </Navigation>
      </Row>
    </HeaderContainer>
  );
});

export default Header;