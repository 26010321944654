module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Moores Pharmacy","short_name":"Moores Pharmacy","description":"Moores Pharmacy is your local pharmacy in London (45 Craven Road, London, W2 3BX, UK)","start_url":"/","background_color":"#fff","theme_color":"#000","display":"standalone","icon":"src/images/sign-512-r.svg","legacy":true,"icon_options":{"purpose":"maskable"},"cache_busting_mode":"query","include_favicon":true,"theme_color_in_head":true,"cacheDigest":"706340733c10ee366e24f41cedd523a2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"manualInit":true,"modulePath":"/opt/build/repo/src/cms/cms.ts","publicPath":"adminy"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
