import styled, { css } from 'styled-components';

export const blocks = [36, 36, 48];
export const sizes = [360, 720, 960];
export const breaks = [0, 720, 990];

export const P_UNIT = '%';
export const PP_UNIT = 'vw';

export const mediaMobileFirst = (mobile: string, tablet: string, desktop = ``) => css`
  ${mobile && css`
    ${mobile}
  `}
  ${tablet && css`
    @media print, screen and (min-width: ${breaks[1]}px) {
      ${tablet}
    }
  `}
  ${desktop && css`
    @media print, screen and (min-width: ${breaks[2]}px) {
      ${desktop}
    }
  `}
`;

export const mediaDesktopFirst = (desktop: string, tablet: string, mobile = ``) => css`
  ${desktop && css`
    ${desktop}
  `}
  ${tablet && css`
    @media print, screen and (max-width: ${breaks[2] - 1}px) {
      ${tablet}
    }
  `}
  ${mobile && css`
    @media print, screen and (max-width: ${breaks[1] - 1}px) {
      ${mobile}
    }
  `}
`;

export type tBreakFn = (blockSize: number, unit: string) => string;

export const mediaMobileFirstWithBlock = (mobile: tBreakFn, tablet?: tBreakFn | null, desktop?: tBreakFn) => css`
  ${mobile(100 / blocks[0], PP_UNIT)};
  @media print, screen and (min-width: ${breaks[1]}px) {
    ${(tablet || mobile)(sizes[1] / blocks[1], 'px')};
  }
  @media print, screen and (min-width: ${breaks[2]}px) {
    ${(desktop || tablet || mobile)(sizes[2] / blocks[2], 'px')};
  }
`;

export const mediaLT = (width: number, unit: string,  style: string) => css`
    @media print, screen and (max-width: ${width - 1}${unit}) {
      ${style}
    }
`;

export const VisibleDesktop = styled.div`
  ${mediaDesktopFirst(`display: block;`, `display: none;`)}
`;

export const VisibleNotDesktop = styled.div`
  ${mediaMobileFirst(`display: block;`, ``, `display: none;`)}
`;

export const VisibleNotMobile = styled.div`
  ${mediaMobileFirst(`display: none;`, `display: block;`)}
`;

export const VisibleDesktopSpan = styled.span`
  ${mediaDesktopFirst(`display: inline;`, `display: none;`)}
`;

export const VisibleNotDesktopSpan = styled.span`
  ${mediaMobileFirst(`display: inline;`, ``, `display: none;`)}
`;

export const Spacer = styled.div<{ size: number }>`
  margin-top: ${props => props.size}px;
`;