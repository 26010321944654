import React, { ReactNode } from "react";
import styled, { css } from "styled-components";
import { sizes, blocks, mediaMobileFirst, mediaMobileFirstWithBlock, breaks, P_UNIT, PP_UNIT } from "src/helpers/responsive";
import { vBlockSize } from "./GlobalStyle";

const DEBUG_IN_DEV = false;
const DEBUG = DEBUG_IN_DEV && process.env.NODE_ENV !== 'production';
const gridColor = 'rgba(0, 192, 192, 0.5)';
const gridBgCssValue = nBlocks => `repeating-linear-gradient(to right, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) calc(${100 / nBlocks}${P_UNIT} - 1px), ${gridColor} calc(${100 / nBlocks}${P_UNIT} - 1px), ${gridColor} calc(${100 / nBlocks}${P_UNIT}));`

// 324, 360, 396, 432, 468, 504, 540, 576, 612, 648, 684, 720, 756, 792, 828, 864, 900, 936
// const fixesResponsiveSizes = [360, 396, 432, 468, 540, 612, 720, 756, 990];
// const fixedResponsiveSizesStyle = `${
//   fixesResponsiveSizes.map((v, i) => i && `@media (max-width: ${v + 1}px) { width: ${fixesResponsiveSizes[i - 1]}px }` || '').reverse().join(' ')
//   + `@media (max-width: ${fixesResponsiveSizes[0]}px) { width: 100${P_UNIT}; }`
// }`;
const fixedResponsiveSizesStyle = ``;

export const Wrapper = styled.div<{ background?: string; vpb?: number; }>`
  ${props => props.background && css`
    background: ${props.background};
  `}
  ${props => props.vpb && css`
    padding: ${props.vpb * vBlockSize}px 0;
  `}
`;

export type RowProps = {
  /** Margin top */
  mt?: string;
  /** Margin bottom */
  mb?: string;
  /** Margin top in blocks */
  mtb?: number;
  /** Margin bottom in blocks */
  mbb?: number;
  /** Use guide lines (use only in development) */
  debug?: boolean;
};

export const Row = styled.div<RowProps>`
  display: flex;
  flex-wrap: wrap;
  ${props => css`
    margin: ${props.mt || 0} auto ${props.mb || 0};
  `}
  ${props => mediaMobileFirst(/* css */`
    width: 100${P_UNIT};
    padding: 0 ${100 / blocks[0]}vw;
    `, `
    padding: 0;
    width: ${sizes[1]}px;
  `, `
    width: ${sizes[2]}px;
  `)}
  ${fixedResponsiveSizesStyle}
  ${props => props.mtb && css`
    margin-top: ${props.mtb * vBlockSize}px;
  `  }
  ${props => props.mbb && css`
    margin-bottom: ${props.mbb * vBlockSize}px;
  `}  
  ${props => (DEBUG || props.debug) && mediaMobileFirst(``, `
    width: ${sizes[1] + 1}px;
  `, `
    width: ${sizes[2] + 1}px;
  `)}  
  ${props => (DEBUG || props.debug) && mediaMobileFirst(/* css */`
    background-image: ${gridBgCssValue(blocks[0])};
  `, /* css */`
    border-left: 1px solid rgba(255, 255, 255, 0.0);
    background-image: ${gridBgCssValue(blocks[1])};
  `, /* css */`
    background-image: ${gridBgCssValue(blocks[2])};
  `)}
  & & {
    ${mediaMobileFirstWithBlock((block, unit) => /* css */`
      margin: 0 -${block}${unit};
      width: calc(100${P_UNIT} + ${2 * block}${unit});
    `)}
    background-image: none;
    border-left: none;
  }
`;

export const RowCol = ({ children = null, className = '' }) => (
  // For people that are too lazy to type <Row> and <Col> manually XD
  <Row className={className}>
    <Col>
      {children}
    </Col>
  </Row>
);

export const RowWithBackground = ({ children, background, vpb }: { children: ReactNode, background: string, vpb?: number  }) => (
  <Wrapper background={background} vpb={vpb}>
    <Row>
      {children}
    </Row>
  </Wrapper>
);

export type Sizes0 = 0 | 1 | 2 | 3 | 4 | 6 | 9 | 12;
export type Sizes1 = Sizes0;
export type Sizes2 = 0 | 1 | 2 | 3 | 4 | 6 | 8 | 12;

export type ColProps = {
  /** Number of columns per row on mobile */
  s0?: Sizes0;
  /** Number of columns per row on table */
  s1?: Sizes1;
  /** Number of columns per row on desktop */
  s2?: Sizes2;
  bgColor?: string;
  /** Vertical padding in blocks */
  vpb?: number;
  /** Vertical padding */
  vp?: string;
  noPadding?: boolean;
  position?: string;
  order?: [number, number?, number?];
};

export const Col = styled.div<ColProps>`
  word-break: break-word;
  ${props => mediaMobileFirst(/* css */`
    display: ${props.s0 === 0 ? 'none' : 'block'};
    padding: ${!props.noPadding ? `${vBlockSize}px ${(props.vpb !== undefined ? props.vpb : 1) * 100 / blocks[0]}${PP_UNIT}` : `0`};
    width: ${100 / (props.s0 || 1)}${P_UNIT};
  `, /* css */`
    display: ${props.s1 === 0 ? 'none' : 'block'};
    padding: ${!props.noPadding ? `${vBlockSize}px ${(props.vpb !== undefined ? props.vpb : 1) * sizes[1] / blocks[1]}px` : `0`};
    width: ${Math.round(sizes[1] / (props.s1 || props.s0 || 1)) }px;
  `, /* css */`
    display: ${props.s2 === 0 ? 'none' : 'block'};
    padding: ${!props.noPadding ? `${vBlockSize}px ${(props.vpb !== undefined ? props.vpb : 1) * sizes[2] / blocks[2]}px` : `0`};
    width: ${Math.round(sizes[2] / (props.s2 || props.s1 || props.s0 || 1)) }px;
  `)}  
  ${props => props.bgColor && css`
    background-color: ${props.bgColor};
  `}
  ${props => props.position && css`
    position: ${props.position};
  `}
  ${props => props.vp && css`
    & {
      padding-top: ${props.vp};
      padding-bottom: ${props.vp};
    }
  `}
  ${props => props.order && mediaMobileFirst(
    `order: ${props.order[0]};`,
    props.order[1] !== undefined ? `order: ${props.order[1]};` : ``,
    props.order[2] !== undefined ? `order: ${props.order[2]};` : ``
  )}
`;

export type ColFlexProps = {
  bgColor?: string;
  /** Default 1 */
  flex?: string;
  /** Vertical padding in blocks */
  vp?: number;
  noPadding?: boolean;
};

export const ColFlex = styled.div<ColFlexProps>`
  /* word-break: break-word; */
  ${props => css`
    flex: ${props.flex || '1'};
    ${mediaMobileFirstWithBlock((block, unit) => /* css */`
      padding: ${!props.noPadding ? `${props.vp !== undefined ? props.vp : `${vBlockSize}px`} ${block}${unit}` : `0`};
    `)}
  `}
  ${props => props.bgColor && css`
    background-color: ${props.bgColor};
  `}
`;

type Sizes = [Sizes0, Sizes1?, Sizes2?];

// const blockSizes = sizes.map((v, i) => v / blocks[i]);

const mobileBlockSize = 1 / blocks[0];
const desktopBlockSize = sizes[2] / blocks[2];

export function getImgInColSizes(s: Sizes = [1, 1, 1], override: [string, string?, string?] = ['', '', '']) {
  const mobile = override[0] || `${(Math.floor((1 / s[0] - mobileBlockSize * 2) * 10000)) / 100}vw`;
  const tablet = override[1] || `${(Math.floor((1 / s[1] - mobileBlockSize * 2) * 10000)) / 100}vw`;
  const desktop = override[2] || `${Math.round(sizes[2] / (s[2] || 1) - desktopBlockSize * 2) }px`;

  if (mobile === tablet) {
    return (
      `(max-width: ${breaks[2] - 1}px) ${tablet}, ` +
      `${desktop}`
    )
  }
  return (
    `(max-width: ${breaks[1] - 1}px) ${mobile}, ` +
    `(min-width: ${breaks[1]}px) and (max-width: ${breaks[2] - 1}px) ${tablet}, ` +
    `${desktop}`
  );
}

export const Row_ForDocsProps = (props: RowProps) => <></>;
export const Col_ForDocsProps = (props: ColProps) => <></>;
export const ColFlex_ForDocsProps = (props: ColFlexProps) => <></>;