import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Head from 'src/gatsby-universal/components/head/head';
import GlobalStyle from 'src/components/GlobalStyle';
import Header from './Layout/Header';
import Footer from './Layout/Footer';


export const Layout = ({ siteTitle = 'Preview', children, ...head }) => (
  <>
    <GlobalStyle />
    <Head siteTitle={siteTitle} {...head} />
    <Header />
    <main>
      {children}
    </main>
    <Footer />
  </>
);

const LayoutWithQuery = ({ preview = false, children, ...props }) => (
  (preview ? (
    <>
    <GlobalStyle />
    <Header {...props} />
    <main>
      {children}
    </main>
    <Footer {...props} />
  </>
  ) : (
    <StaticQuery
      query={graphql`
        query LayoutMainQuery {
          site {
            siteMetadata {
              siteTitle
            }
          }
        }
      `}
      render={data => (
        <Layout siteTitle={data.site.siteMetadata.siteTitle} data={data} {...props}>
          {children}
        </Layout>
      )}
    />
  ))
);

// export default Layout;
export default LayoutWithQuery;
