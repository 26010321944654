import { createGlobalStyle } from 'styled-components';
import { mediaDesktopFirst } from 'src/helpers/responsive';

export const fontFamilyHeading = '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';
// export const fontFamily = 'Georgia, Cambria, Times, "Times New Roman", serif;';
export const fontFamily = fontFamilyHeading;
export const vBlockSize = 20;
export const colorLightText = '#707070';

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }
  * {
    margin: 0;
    padding: 0;
  }
  ::selection {
    background-color: #000b;
    color: #fff;
    text-decoration-color: #fff;
  }
  img::selection {
    background-color: #0000;
  }
  html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    font-size: 18px;
    line-height: 1.5;
    font-family: ${fontFamily};
    color: #000;
  }
  #gatsby-focus-wrapper {
    ${mediaDesktopFirst(``, ``, `
      padding-top: 64px;
    `)}
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  main {
    flex: 1;
  }

  h1, h2, h3, h4,
  .h1, .h2, .h3, .h4 {
    font-family: ${fontFamilyHeading};
    line-height: 1.25;
    font-weight: 500;
    margin: 0 0 ${vBlockSize}px;
    &:last-child {
      margin-bottom: 0;
    }
    a {
      color: #000;
      text-decoration: underline;
      &:hover {
        opacity: 0.875;
      }
    }
  }
  h1, .h1 {
    font-size: 48px;
  }
  h2, .h2 {
    font-size: 32px;
  }
  h3, .h3 {
    font-size: 1rem;
    font-weight: 700;
  }
  h4, .h4 {
    font-size: 1rem;
    font-weight: 700;
    margin: 0 0 ${vBlockSize * 0.5}px;
  }
  ${mediaDesktopFirst(``, ``, `
    h1 { 
      font-size: 32px;
    }
    h2 {
      font-size: 24px;
    }
  `)}
  p {
    margin: 0 0 ${vBlockSize * 0.5}px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .ff-h {
    font-family: ${fontFamilyHeading}
  }
  .ff-p {
    font-family: ${fontFamily}
  }
  .c-light{
    color: ${colorLightText};
  }

  ul {
    list-style: none;
  }
  a {
    color: #000;
    text-decoration: underline;
  }
  address {
    font-style: normal;
  }
  em {
    color: #777;
  }
  img {
    width: 100%;
    border: none;
    user-select: none;
    -webkit-user-drag: none;
  }

  /*
  .label {
    font-weight: normal;
    text-transform: uppercase;
    color: #333;
    text-decoration: none;
    font-family: ${fontFamilyHeading};
    letter-spacing: 0.6px;
  }
  body .btn {
    display: inline-block;
    border: 1px solid #505050;
    color: #505050;
    line-height: 30px;
    border: 1px solid #505050;
    border-radius: 16px;
    padding: 0 24px;
    text-decoration: none;
    margin-right: 20px;
    @media screen and (max-width: 390px) {
      padding: 0 18px;
      font-size: 14px;
      margin-right: 12px;
    }
    @media screen and (max-width: 330px) {
      padding: 0 16px;
    }
    &.primary {
      color: #fff;
      background-color: #505050;
    }
  }
  */
  /* PRINT */
  /* @media print {
    @todo
  } */

  /* TABLET AND MOBILE */
  /* @media screen and (max-width: 989px) {
    h1, .h1 {
      font-size: 30px;
    }
  } */

  /* ONLY MOBILE */
  /* @media screen and (max-width: 639px) {
    h2, .h2 {
      font-size: 21px;
    }
  } */
`;

export default GlobalStyle;